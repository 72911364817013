<template>
  <div class="flex flex-col items-center w-full px-3 pt-10 bg-gray-800 md:px-0">
    <div class="flex flex-col w-4/5 max-w-sm py-4 mt-4 mb-4 text-center bg-white rounded-md md:max-w-lg">
      <div class="flex justify-between px-8">
        <div class="flex flex-col items-start justify-start py-5">
          <app-image v-if="getBusinessLogo()" :src="getBusinessLogo()" class="flex-none w-16 bg-white" />
          <app-image v-else-if="getBusinessIcon()" :src="getBusinessIcon()" class="flex-none w-16 bg-white" />
          <div class="pt-4">
            <p class="mb-2 text-sm font-medium text-gray-900 uppercase">
              {{ business?.name }}
            </p>
          </div>
          <div>
            <p class="mb-2 text-sm text-gray-400 uppercase">
              {{ business?.detail?.company_reg_no }}
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-end py-5 text-right">
          <p class="mb-2 font-bold text-right uppercase">{{ $t("layout.header.invoice") }}</p>
          <app-test-mode-badge :testMode="testMode" />
          <p class="text-sm text-gray-400">{{ $t("invoice.invoice_pdf.amount_to_pay") }}</p>
          <p class="text-2xl font-bold">{{ $formats.currency(invoice.currency, invoice.total_amount) }}</p>
          <p class="text-sm text-gray-400">{{ $t(`invoice.create.types.${invoice.type}`) }}</p>
        </div>
      </div>

      <div class="w-full mb-2 border-t"></div>
      <div class="flex flex-row">
        <div class="flex flex-col justify-start mx-8 space-y-2">
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("general.to") }}
            </p>
            <p class="text-sm">{{ customer.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("general.from") }}
            </p>
            <p class="text-sm">{{ business?.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("invoice.invoice_pdf.memo") }}
            </p>
            <p class="text-sm">{{ invoice.memo }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <app-button-outline>
              <app-icon-download></app-icon-download>
              Download Invoice
            </app-button-outline>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-4/5 max-w-sm py-4 mt-4 mb-4 text-center bg-white rounded-md md:max-w-lg">
      <p class="pl-8 text-left text-gray-400">Product/Item List</p>
      <table class="table">
        <tr v-for="product in invoice.items" :key="product._id">
          <td class="pl-8">
            <p class="font-bold text-left">{{ product.name ? product.name : "-" }} <span> {{ "(x" + product.quantity
                + ")"
            }}</span></p>
          </td>
          <td class="text-left">{{ $formats.currency(product.currency, product.price) }} / {{ product.unit_label }}</td>
          <td class="pr-8 text-right">
            <p>{{ $formats.currency(product.currency, product.price) }}</p>
          </td>
        </tr>

        <tr class="text-gray-400 row">
          <td colspan="2" class="text-right">
            <p>{{ $t("invoice.invoice_pdf.tax_option") }}</p>
          </td>
          <td>
            <p class="pr-8 text-right"> {{ invoice.tax_name ? invoice.tax_name : "-" }} / {{ invoice.tax_rate ?
                invoice.tax_rate : "-"
            }}</p>
          </td>
        </tr>
        <tr class="p-5 mx-0 border-t row">
          <td class="pt-8"></td>
          <td class="pt-8 text-right">
            <p class="font-bold">{{ $t("invoice.invoice_pdf.subtotal") }}:</p>
          </td>
          <td class="pt-8 pr-8 text-right">
            <p class="font-bold">{{ $formats.currency(invoice.currency, invoice.subtotal) }}</p>
          </td>
        </tr>
        <tr class="space-y-2 text-gray-400 row">
          <td></td>
          <td class="text-right">
            <p>{{ $t("invoice.invoice_pdf.total_tax") }}:</p>
          </td>
          <td class="pr-8 text-right">
            <p> {{ $formats.currency(invoice.currency, invoice.total_tax) }}</p>
          </td>
        </tr>
        <tr class="row">
          <td></td>
          <td class="pt-4 text-right">
            <p class="text-lg font-bold text-right text-primary">{{ $t("invoice.invoice_pdf.total_due") }}:</p>
          </td>
          <td class="pr-8 text-right">
            <p class="text-lg font-bold text-primary">{{ $formats.currency(invoice.currency, invoice.total_amount) }}</p>
          </td>
        </tr>
        <tr class="space-y-2 row">
          <td colspan="3" class="pb-8 pr-8 text-right">
            <p class="text-sm text-gray-400">{{ $t(`invoice.create.types.${invoice.type}`) }}</p>
          </td>
        </tr>
      </table>
      <div class="w-full mb-2 border-t"></div>
      <div class="p-5 text-justify">
        <p class="my-2">Invoice Payment ID : {{ customer.invoice_prefix ? customer.invoice_prefix : "-" }}</p>
        <p class="my-2">If you have any questions about this invoice, contact us at {{
            business.support_detail.support_email
        }} or call us at {{ business.support_detail.support_tel_no }}
        </p>
        <p class="my-2">Cheers</p>
        <p class="my-2">{{ business?.name }}</p>
        <p class="my-2">If you’re having trouble with the button above, copy and paste the URL below into your web
          browser.</p>
        <p class="py-2 my-2 text-center text-blue-600 bg-blue-100">{{ payment_link_url }}</p>
      </div>
    </div>
    <div class="justify-center m-10">
      <app-logo-powered-by-payje-light />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      testMode: false,
    }
  },
  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }
  },

  computed: {
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link?.business
        : this.$store.getters["businessStore/business"];
    },
    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/invoice/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business.media?.find((media) => media.collection_name == "icon")
        ?.original_url;
    },
  },
};
</script>

<style scoped>
td {
  padding: 5px;
}
</style>